<template>
  <v-app>
    <v-app-bar app color="primary" flat>
      <v-container class="py-0 fill-height">
        <v-img
          :lazy-src="require('@/assets/images/logo.png')"
          max-height="50"
          max-width="100"
          contain
          :src="require('@/assets/images/logo.png')"
        ></v-img>
        <v-spacer></v-spacer>
        <span v-if="!$vuetify.breakpoint.mobile">
          <v-btn
            dark
            :to="link.link"
            v-for="(link, inx) in links"
            :key="inx"
            text
            class="mx-2"
          >
            {{ link.title }}
          </v-btn>
          <v-btn v-if="!_user" to="/login" class="px-6" dark outlined
            >Login</v-btn
          >
          <v-btn v-else to="/myAccount" class="px-6" dark outlined
            >My-account</v-btn
          >
        </span>
        <v-menu rounded offset-y v-else>
          <template v-slot:activator="{ attrs, on }">
            <v-btn outlined class="white--text" v-bind="attrs" v-on="on">
              Menu
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              :to="link.link"
              v-for="(link, inx) in links"
              :key="inx"
              link
            >
              <v-list-item-title> {{ link.title }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!_user" to="/login" link>
              <v-list-item-title> Login</v-list-item-title>
            </v-list-item>
            <v-list-item v-else to="/myAccount" link>
              <v-list-item-title> My-account</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-container>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer color="primary " padless>
      <v-container>
        <v-row justify="center" no-gutters>
          <v-col class="py-2 white--text" cols="12">
            <v-divider dark class="ma-2"></v-divider>
            <v-row no-gutters justify="space-between">
              <v-col>
                © {{ new Date().getFullYear() }} BCX All rights reserved.</v-col
              >
              <v-col cols="auto">
                <v-btn
                  v-for="(icon, inx) in icons"
                  :key="inx"
                  dark
                  icon
                  @click="openLink(icon.link)"
                >
                  <v-icon >
                    {{ icon.icon }}
                  </v-icon>
                </v-btn></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  name: 'App',

  components: {},

  data: () => ({
    links: [
      { title: 'Home', link: '/home' },
      { title: 'Tour', link: '/tour' },
      { title: 'Be Our Agent', link: '/beOurAgent' },
      { title: 'Contact Us', link: '/contactUs' }
    ],
    icons: [{ icon: 'mdi-facebook', link: 'https://www.facebook.com/bigcountrytour/?_rdc=2&_rdr' },
      { icon: 'icon-line', link: 'http://line.me/ti/p/~@bigcountrytour' }]
  }),
  computed: {
    _user: get('auth/userInfo')
  },
  methods: {
    openLink (link) {
      if (link) window.open(link, '_blank')
    }
  }
}
</script>
