import Vue from 'vue'

import Vue2Editor from 'vue2-editor'

import myMixins from '@/plugins/mixins'
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js'
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js'
import driverRouterVueRouter
  from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js'
import auth from '@websanova/vue-auth/src/v2.js'

import App from './App.vue'
import dialogComponent from './components/dialog'
import axios from './plugins/axios'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.component('Dialog-component', dialogComponent)
Vue.use(Vue2Editor)

Vue.config.productionTip = false

Vue.use(auth, {
  plugins: {
    http: Vue.axios, // Axios
    router: Vue.router
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios, // Axios
    router: driverRouterVueRouter
  },
  options: {
    rolesKey: 'type',
    tokenDefaultKey: 'resData.token',
    notFoundRedirect: { name: 'home' },
    fetchData: { enabled: false, fetchUser: false },
    loginData: { url: '/agent/login', fetchUser: false, redirect: null, staySignedIn: true }
  },
  loginData: { url: 'http://localhost:5005/api/authenticate', fetchUser: false }
})

Vue.mixin(myMixins)
new Vue({
  store,
  router,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')
