import Vue from 'vue'

import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  { path: '/home', component: () => import('@/views/home.vue') },
  { path: '/tour', component: () => import('@/views/tour.vue') },
  {
    path: '/tour/detail/:id',
    component: () => import('@/views/tourDetail.vue')

  },
  { path: '/tour/view/detail/:id', component: () => import('@/views/tourDetailView.vue') },
  { path: '/search', component: () => import('@/views/search.vue') },
  { path: '/beOurAgent', component: () => import('@/views/beOurAgent.vue') },
  { path: '/contactUs', component: () => import('@/views/contactUs.vue') },
  {
    path: '/myAccount',
    component: () => import('@/views/myAccount.vue')

  },
  { path: '/login', component: () => import('@/views/login.vue') },
  { path: '/forgotPassword', component: () => import('@/views/forgotPassword.vue') },
  { path: '/canNotLogin', component: () => import('@/views/canNotLogin') }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

Vue.router = router

export default router
