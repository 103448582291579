import Vue from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL + process.env.VUE_APP_BASE_URL

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, function (err) {
  return Promise.reject(err)
})

axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  // Handle errors here
  if (error.response.status === 401) {
    localStorage.removeItem('token')
    window.location = '/login'
  }
  return Promise.reject(error)
})

Vue.use(VueAxios, axios)

export default {
  root: process.env.API_URL
}
