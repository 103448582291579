import Vue from 'vue'

import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2D3D8B',
        success: '#06953F',
        info: '#4661E3',
        error: '#F25767',
        warning: '#FCB606'
      }
    }
  }
})
