<template>
  <v-dialog
    v-model="showModal"
    width="500"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title class="text-h5 justify-center">
        {{ title }}
      </v-card-title>
      <v-card-text class="text-h6 text-center">
        {{ description }}
      </v-card-text>

      <v-card-actions class="justify-space-around">
        <v-row v-if="typeAlert=== 'confirm'" justify="space-around" no-gutters>
           <v-col cols="4">
            <v-btn
              block
              outlined
              color="primary"
              @click="handleButtonClick(false)"
            >
              CANCEL
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              block
              color="primary"
              @click="handleButtonClick(true)"
            >
              CONFIRM
            </v-btn>
          </v-col>

        </v-row>
        <v-row v-else no-gutters align="center" justify="center" class="mt-5">
          <v-col cols="3">
            <v-btn block color="warning" @click="showModal = false">
              CLOSE
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      callback: _ => null,
      showModal: false,
      typeAlert: 'alert',
      description: '',
      title: ''
    }
  },
  methods: {
    show ({ typeAlert = '', description = '', title = '' } = {}) {
      this.typeAlert = typeAlert
      this.description = description
      this.title = title
      this.showModal = true
      return new Promise((resolve, reject) => {
        this.callback = resolve
      })
    },
    handleButtonClick (yesNo) {
      this.showModal = false
      this.callback(yesNo)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
