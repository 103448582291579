export default {
  methods: {
    selectFile ({ multiple, accept, limit }) {
      return new Promise((resolve) => {
        const input = document.createElement('input')
        input.type = 'file'
        input.multiple = multiple
        input.accept = accept
        input.addEventListener('change', () => {
          if (limit) {
            return resolve(Array.from(input.files).slice(0, limit))
          }
          return resolve(input.files)
        })
        input.click()
      })
    },

    phoneFormat (input) {
      if (!input || isNaN(input)) return ''
      if (typeof input !== 'string') input = input.toString()
      if (input.length === 10) {
        return input.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
      } else if (input.length < 10) {
        return ''
      } else if (input.length > 10) {
        return ''
      } else {
        return ''
      }
    },
    truncateString (str, num = 250) {
      // If the length of str is less than or equal to num
      // just return str--don't truncate it.
      if (str.length <= num) {
        return str
      }
      // Return str truncated with '...' concatenated to the end of str.
      return str.slice(0, num) + '...'
    }
  }
}
