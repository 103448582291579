import Vue from 'vue'

import Vuex from 'vuex'
import pathify from 'vuex-pathify'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth.js'

const dataState = createPersistedState({
  paths: ['auth']
})

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [dataState, pathify.plugin],
  modules: {
    auth
  }
})
